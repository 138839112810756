import axios from "axios";
import { API_URL } from "../api/config";
import { autoLogout } from "./logout";
import { getCookie } from "./cookie";

// const history = useHistory();

export async function getPaginate(url, page = 1, limit = 10, params = "") {
  const res = axios
    .get(url + "?page=" + page + "&limit=" + limit + params, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_kelola_session")).accessToken
        }`,
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        discordWebhook(url + " " + error?.response);
        return error?.response;
      }
    });

  return res;
}

export async function get(url, params = "") {
  const res = axios
    .get(url + params, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_kelola_session")).accessToken
        }`,
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        autoLogout();
      } else {
        discordWebhook(url + " " + error.response);

        return error.response;
      }
    });

  return res;
}

export async function post(url, value) {
  const res = await axios
    .post(url, value, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_kelola_session")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        autoLogout();
      } else {
        discordWebhook(url + " " + error.response);

        return error.response;
      }
    });

  return res;
}

export async function put(url, value) {
  const res = axios
    .put(url, value, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_kelola_session")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        autoLogout();
      } else {
        discordWebhook(url + " " + error.response);

        return error.response;
      }
    });

  return res;
}

export async function patch(url, value) {
  const res = axios
    .patch(url, value, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_kelola_session")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        autoLogout();
      } else {
        discordWebhook(url + " " + error.response);

        return error.response;
      }
    });

  return res;
}

export async function del(url) {
  const res = axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_kelola_session")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        autoLogout();
      } else {
        discordWebhook(url + " " + error.response);

        return error.response;
      }
    });

  return res;
}

export async function discordWebhook(message) {
  // const DISCORD_WEBHOOK =
  //   "https://discord.com/api/webhooks/1163266486715027526/IW639r_SGUzmAqvwgl57qK18d77_fTXfzvelYRzV1h_cOCAG-5Wvo1Bblz8ibniYZwS4";
  // const value = { content: message };
  // const res = await axios
  //   .post(DISCORD_WEBHOOK, JSON.stringify(value), {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json;",
  //     },
  //   })
  //   .then((res) => {
  //     return res;
  //   })
  //   .catch((error) => {
  //     return error.response;
  //   });
  // return res;
}

export async function exportData(url, params = "", filename, extension) {
  const res = await axios
    .get(url + params, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("_kelola_session")).accessToken
        }`,
      },
      responseType: "blob",
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename + "." + extension);
      document.body.appendChild(link);
      link.click();

      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        // autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}
